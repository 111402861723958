<template>
  <div class="xinsheng-warp system-warp">
    <FilterForm
      :formData="filterData"
      :filters="filters"
      @submit="filterSubmit"
      @reset="onReset"
    />
    <div class="system-btn user-btn">
      <el-button type="primary" size="small" icon="el-icon-plus" @click="handleNew"
        >添加员工</el-button
      >
      <!--      <el-button-->
      <!--        size="small"-->
      <!--        @click="batchDelete"-->
      <!--      >删 除</el-button>-->
      <el-button size="small" @click="loginEnable">一键允许登录</el-button>
      <el-button size="small" @click="loginDisabled">一键禁止登陆</el-button>
      <el-button size="small" @click="customEnable">一键开启接受</el-button>
      <el-button size="small" @click="customDisabled">一键禁止接受</el-button>
    </div>
    <div class="container">
      <div class="left">
        <el-input
          v-model="company"
          placeholder="输入关键字进行过滤"
          size="small"
          suffix-icon="el-icon-search"
          class="company-block"
          clearable
        ></el-input>
        <el-tree
          ref="treeRef"
          class="depart-tree"
          :data="treeData"
          default-expand-all
          :props="{
            children: 'children',
            label: 'fullName',
          }"
          @node-click="handleNodeClick"
          :filter-node-method="filterNodeMethod"
          :expand-on-click-node="false"
        ></el-tree>
      </div>
      <div class="right">
        <UserTable
          v-if="tableData"
          :tableColumn="tableColumn"
          :tableData="tableData"
          :selection="true"
          @selection="handleSelectionChange"
          @edit="goToEdit"
          @delete="goToDelete"
          @view="goToView"
          @resetPassword="handleResetPassword"
        />
        <UserPagination
          :total="total"
          @sizeChange="sizeChange"
          @currentChange="currentChange"
        />
      </div>
    </div>
    <UserDialog
      :title="title"
      :isShowDialog="isShowDialog"
      :rules="rules"
      :forms="forms"
      :formData="formData"
      :width="750"
      @submit="handleSubmit"
      @close="handleColse"
    />
    <UserDialog
      title="修改密码"
      :isShowDialog="isShowPassword"
      :rules="passwordRules"
      :forms="passwordForms"
      :formData="passwordFormData"
      :width="500"
      @submit="handlePasswordSubmit"
      @close="handlePasswordColse"
    />
    <DialogView
      title="查看"
      :isShowDialog="isShowView"
      :forms="forms"
      :formData="formData"
      :width="750"
      @close="handleViewColse"
    />
  </div>
</template>

<script>
import FilterForm from "@/components/filter";
import { computed, onMounted, reactive, ref, watch } from "vue";
import { ElButton, ElInput, ElMessage, ElMessageBox, ElTree } from "element-plus";
import { tableColumn, forms, passwordForms } from "./index.js";
import UserTable from "@/components/table";
import UserPagination from "@/components/pagination";
import UserDialog from "@/components/dialog-form";
import DialogView from "@/components/dialog-view";
import { useStore } from "vuex";
import {
  postUser,
  putUser,
  deleteUser,
  putLoginEnable,
  putLoginDisabled,
  putCustomEnable,
  putCustomDisabled,
  batchDeleteUser,
  resetPasseord,
} from "@/api/method/system";
import { reset, validatePass, validatePhone, validateEmail } from "@/until";

export default {
  name: "SystemUser",
  components: {
    FilterForm,
    [ElButton.name]: ElButton,
    [ElInput.name]: ElInput,
    [ElTree.name]: ElTree,
    UserTable,
    UserPagination,
    UserDialog,
    DialogView,
  },
  setup() {
    const { dispatch, state } = useStore();
    const pagination = reactive({
      page: 1,
      size: 10,
    });
    const filterData = reactive({
      search: null,
      status: null,
      deptId: null,
    });
    const formData = reactive({
      id: null,
      nickname: null,
      telephone: null,
      password: null,
      password1: null,
      sex: null,
      deptId: null,
      roleId: null,
      email: null,
      birthday: null,
      customerDailyActualMax: null,
      customerDailyMax: null,
      newCustomerDailyMax: null,
      customerSwitch: 0,
      status: 1,
      probation: 0,
      hiredate: null,
      probationPerformance: null,
      regularPerformance: null,
    });
    const filters = reactive([
      [
        {
          label: "员工",
          type: "input",
          attr: "search",
          placeholder: "搜索员工手机号或姓名",
          suffixIcon: "el-icon-search",
          column: 7,
          labelWidth: "54px",
        },
        {
          label: "状态",
          type: "select",
          attr: "status",
          placeholder: "请选择",
          column: 7,
          option: [
            { label: "禁用", val: 0 },
            { label: "启用", val: 1 },
          ],
          labelWidth: "72px",
        },
        {
          label: "在职状态",
          type: "select",
          attr: "dimission",
          placeholder: "请选择",
          column: 7,
          option: [
            { label: "在职", val: 1 },
            { label: "离职", val: 0 },
          ],
          labelWidth: "86px",
        },
        {
          type: "button",
          column: 3,
        },
      ],
    ]);

    const company = ref("");
    const tableData = computed(() => state.userList && state.userList.records);
    const total = computed(() => state.userList && state.userList.total);
    const treeData = computed(() => state.departmentTree);

    const isShowDialog = ref(false);
    const title = ref("添加");

    const handleNew = () => {
      isShowDialog.value = true;
    };
    const handleColse = () => {
      isShowDialog.value = false;
      reset(formData);
    };

    const currentChange = (page) => {
      pagination.page = page;
      getList();
    };
    const sizeChange = (size) => {
      pagination.size = size;
      getList();
    };
    const getList = () => {
      dispatch("userList", { ...filterData, ...pagination });
    };
    const getDepartTree = () => {
      dispatch("departmentTree");
    };
    const getDepartList = async () => {
      await dispatch("departmentList");
      forms[2][1].option = state.departmentList.map((item) => ({
        label: item.fullName,
        val: item.id,
      }));
    };
    const getRoleList = async () => {
      await dispatch("roleList");
      forms[3][0].option =
        state.roleList &&
        state.roleList.map((item) => ({
          label: item.name,
          val: item.id,
        }));
    };

    const filterSubmit = () => getList();
    const onReset = () => {
      reset(filterData);
      getList();
    };

    onMounted(() => {
      getList();
      getDepartList();
      getDepartTree();
      getRoleList();
    });

    const idArr = ref([]);
    const handleSelectionChange = (arr) => {
      idArr.value = arr.map((item) => item.id);
    };

    const batchDelete = async () => {
      if (!idArr.value.length) {
        ElMessage.warning("请勾选用户后操作");
        return;
      }
      ElMessageBox.confirm("确定要删除这些用户吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const res = await batchDeleteUser(idArr.value);
          if (res.code == 200) {
            idArr.value = [];
            ElMessage.success("批量删除成功");
            getList();
          } else {
            ElMessage({
              type: "error",
              message: res.msg,
            });
          }
        })
        .catch(() => {});
    };
    const loginEnable = async () => {
      if (!idArr.value.length) {
        ElMessage.warning("请勾选用户后操作");
        return;
      }
      const res = await putLoginEnable(idArr.value);

      if (res.code == 200) {
        idArr.value = [];
        ElMessage.success("一键允许登录成功！");
        getList();
      }
    };
    const loginDisabled = async () => {
      if (!idArr.value.length) {
        ElMessage.warning("请勾选用户后操作");
        return;
      }
      const res = await putLoginDisabled(idArr.value);
      if (res.code == 200) {
        idArr.value = [];
        ElMessage.success("一键禁止登录成功！");
        getList();
      }
    };
    const customEnable = async () => {
      if (!idArr.value.length) {
        ElMessage.warning("请勾选用户后操作");
        return;
      }
      const res = await putCustomEnable(idArr.value);
      if (res.code == 200) {
        idArr.value = [];
        ElMessage.success("一键开启接受成功");
        getList();
      }
    };
    const customDisabled = async () => {
      if (!idArr.value.length) {
        ElMessage.warning("请勾选用户后操作");
        return;
      }
      const res = await putCustomDisabled(idArr.value);
      if (res.code == 200) {
        idArr.value = [];
        ElMessage.success("一键禁止接受成功");
        getList();
      }
    };

    const handleNodeClick = (node) => {
      filterData.deptId = node.id;
      getList();
    };
    const treeRef = ref(null);
    watch(
      () => company.value,
      () => {
        treeRef.value.filter(company.value);
      }
    );

    const filterNodeMethod = (value, data) => {
      if (!value) return true;
      return data.fullName.indexOf(value) !== -1;
    };

    const rules = reactive({
      nickname: [{ required: true, message: "请输入用户名称", trigger: "submit" }],
      telephone: [
        {
          required: true,
          validator: (rule, value, callback) => validatePhone(rule, value, callback),
          trigger: "submit",
        },
      ],
      password: [{ required: true, message: "请输入密码", trigger: "submit" }],
      password1: [
        {
          required: true,
          validator: (rule, value, callback) =>
            validatePass(rule, value, callback, formData.password),
          trigger: "submit",
        },
      ],
      deptId: [{ required: true, message: "请选择部门", trigger: "submit" }],
      roleId: [{ required: true, message: "请选择角色", trigger: "submit" }],
      email: [
        {
          validator: (rule, value, callback) => validateEmail(rule, value, callback),
          trigger: "submit",
        },
      ],
    });
    const handleSubmit = async () => {
      // eslint-disable-next-line no-unused-vars
      const { id, password1, ...params } = formData;
      if (id) {
        const res = await putUser({ id, ...params });
        if (res.code == 200) {
          ElMessage.success("编辑成功！");
          handleColse();
          getList();
          if (params.roleId != initalRoleId.value) {
            window.location.reload();
          }
        } else {
          ElMessage.error(res.msg);
        }
      } else {
        const res = await postUser(params);
        if (res.code == 200) {
          ElMessage.success("保存成功！");
          handleColse();
          getList();
        } else {
          ElMessage.error(res.msg);
        }
      }
    };
    const initalRoleId = ref(null);
    const getUserDetail = (id) => {
      const {
        nickname,
        telephone,
        sex,
        deptId,
        roleId,
        email,
        birthday,
        customerDailyActualMax,
        customerDailyMax,
        newCustomerDailyMax,
        customerSwitch,
        status,
        password,
        hiredate,
        probation,
        probationPerformance,
        regularPerformance,
      } = state.userDetail;
      formData.id = id;
      formData.nickname = nickname;
      formData.telephone = telephone;
      formData.password = password;
      formData.password1 = password;
      formData.sex = sex;
      formData.deptId = deptId;
      formData.roleId = roleId;
      initalRoleId.value = roleId;
      formData.email = email;
      formData.birthday = birthday;
      formData.customerDailyActualMax = customerDailyActualMax;
      formData.customerDailyMax = customerDailyMax;
      formData.newCustomerDailyMax = newCustomerDailyMax;
      formData.customerSwitch = customerSwitch;
      formData.status = status;
      formData.hiredate = hiredate;
      formData.probation = probation;
      formData.probationPerformance = probationPerformance;
      formData.regularPerformance = regularPerformance;
    };
    const goToEdit = async (id) => {
      isShowDialog.value = true;
      title.value = "编辑";
      await dispatch("userDetail", id);
      getUserDetail(id);
    };

    const goToDelete = (row) => {
      let message = row.dimission == "0" ? "在职" : "离职";
      ElMessageBox.confirm("确定要将该用户标记为" + message + "吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const res = await deleteUser(row.id);
          if (res.code == 200) {
            ElMessage({
              type: "success",
              message: `标记${message}成功!`,
            });
            getList();
          } else {
            ElMessage({
              type: "error",
              message: res.msg,
            });
          }
        })
        .catch(() => {});
    };

    const isShowView = ref(false);
    const goToView = async (id) => {
      isShowView.value = true;
      title.value = "编辑";
      await dispatch("userDetail", id);
      getUserDetail(id);
    };

    const handleViewColse = () => {
      isShowView.value = false;
      reset(formData);
    };

    /**重置密码 */
    const isShowPassword = ref(false);
    const userId = ref(null);
    const passwordRules = reactive({
      password: [{ required: true, message: "请输入密码", trigger: "submit" }],
      password1: [
        {
          required: true,
          validator: (rule, value, callback) =>
            validatePass(rule, value, callback, passwordFormData.password),
          trigger: "submit",
        },
      ],
    });

    const handleResetPassword = (id) => {
      isShowPassword.value = true;
      userId.value = id;
    };
    const handlePasswordSubmit = async () => {
      const res = await resetPasseord({
        id: userId.value,
        password: passwordFormData.password,
      });
      if (res.code == 200) {
        ElMessage.success("重置密码成功！");
        isShowPassword.value = false;
      } else {
        ElMessage.error(res.msg);
      }
    };
    const handlePasswordColse = () => {
      isShowPassword.value = false;
    };
    const passwordFormData = reactive({
      password1: null,
      password: null,
    });

    return {
      filters,
      filterData,
      filterSubmit,
      onReset,
      company,
      tableColumn,
      tableData,
      handleNew,
      isShowDialog,
      rules,
      forms,
      formData,
      total,
      handleSelectionChange,
      treeData,
      handleNodeClick,
      handleSubmit,
      handleColse,
      title,
      goToEdit,
      goToDelete,
      goToView,
      handleViewColse,
      isShowView,
      filterNodeMethod,
      treeRef,
      batchDelete,
      loginEnable,
      loginDisabled,
      customEnable,
      customDisabled,
      isShowPassword,
      passwordForms,
      passwordFormData,
      passwordRules,
      handleResetPassword,
      handlePasswordSubmit,
      handlePasswordColse,
      currentChange,
      sizeChange,
    };
  },
};
</script>

<style lang="scss" scoped>
.user-btn {
  padding-bottom: 40px;
}
.container {
  display: flex;
  height: auto;
  //   min-height: 100%;
  .left {
    width: 250px;
    background: #f5f6f9;
    margin-right: 24px;
    padding: 20px;
  }
  .right {
    flex: 1;
    background-color: #fff;
    overflow: hidden;
  }
}
</style>

<style lang="scss">
.company-block {
  margin-bottom: 20px;
}

.company-block .el-input__inner {
  background: white !important;
}
.depart-tree {
  background: #f5f6f9;
  .el-tree-node__content {
    background: #f5f6f9;
  }

  .el-tree-node.is-current > .el-tree-node__content {
    background-color: #406fff;
    color: white;
  }
}
</style>
