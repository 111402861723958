export const tableColumn = [
	{
		label: '员工姓名',
		prop: 'nickname',
		fixed: true,
		width: '150px',
	},
	{
		label: '手机账号',
		prop: 'telephone',
		width: '150px',
	},
	{
		label: '性别',
		prop: 'sex',
		width: '150px',
		isCustomize: true,
		tableType: 'sexNumber',
	},
	{
		label: '所属部门',
		prop: 'deptName',
		width: '150px',
	},
	{
		label: '所属角色',
		prop: 'roleName',
		width: '150px',
	},
	{
		label: '客户保有量',
		prop: 'newCustomerDailyMax',
		width: '150px',
	},
	{
		label: '线索保有量',
		prop: 'customerDailyMax',
		width: '150px',
	},
	{
		label: '日线索分配数',
		prop: 'customerDailyActualMax',
		width: '150px',
	},
	{
		label: '新线索',
		prop: 'customerSwitch',
		width: '150px',
		isCustomize: true,
		tableType: 'customerSwitch',
	},
	{
		label: '状态',
		prop: 'status',
		width: '150px',
		isCustomize: true,
		tableType: 'userStatus',
	},
	{
		label: '在职状态',
		prop: 'dimission',
		width: '150px',
		isCustomize: true,
		tableType: 'dimissionStatus',
	},
	{
		label: '操作',
		prop: 'name',
		fixed: 'right',
		width: '250px',
		isCustomize: true,
		tableType: 'userOperate',
	},
]

export const forms = [
	[
		{
			label: '员工姓名',
			type: 'input',
			attr: 'nickname',
			placeholder: '请输入员工姓名',
			column: 12,
			labelWidth: '120px',
		},
		{
			label: '手机号',
			type: 'input',
			attr: 'telephone',
			placeholder: '请输入手机号',
			column: 12,
			labelWidth: '120px',
		},
	],
	[
		{
			label: '登录密码',
			type: 'input',
			attr: 'password',
			inputType: 'password',
			placeholder: '请输入登录密码',
			column: 12,
			labelWidth: '120px',
		},
		{
			label: '确认密码',
			type: 'input',
			attr: 'password1',
			inputType: 'password',
			placeholder: '请确认密码',
			column: 12,
			labelWidth: '120px',
		},
	],
	[
		{
			label: '性别',
			type: 'select',
			attr: 'sex',
			placeholder: '请选择性别',
			column: 12,
			labelWidth: '120px',
			option: [
				{ label: '男', val: 0 },
				{ label: '女', val: 1 },
			],
		},
		{
			label: '所属部门',
			type: 'select',
			attr: 'deptId',
			placeholder: '请选择所属部门',
			column: 12,
			labelWidth: '120px',
			option: [],
		},
	],
	[
		{
			label: '所属角色',
			type: 'select',
			attr: 'roleId',
			placeholder: '请选择所属角色',
			column: 12,
			labelWidth: '120px',
			option: [
				{ label: '管理员', val: 0 },
				{ label: '经理', val: 1 },
			],
		},
		{
			label: '电子邮箱',
			type: 'input',
			attr: 'email',
			placeholder: '请输入电子邮箱',
			column: 12,
			labelWidth: '120px',
		},
	],
	[
		{
			label: '出生年月',
			type: 'date',
			attr: 'birthday',
			placeholder: '出生年月',
			column: 12,
			labelWidth: '120px',
			valueFormat: 'YYYY-MM-DD',
		},
		{
			label: '日线索分配数',
			type: 'inputNumber',
			disabled: true,
			attr: 'customerDailyActualMax',
			placeholder: '请输入日线索分配数',
			column: 12,
			labelWidth: '120px',
		},
	],
	[
		{
			label: '线索保有量',
			type: 'inputNumber',
			attr: 'customerDailyMax',
			placeholder: '请输入线索保有量',
			column: 12,
			labelWidth: '120px',
		},
		{
			label: '客户保有量',
			type: 'inputNumber',
			attr: 'newCustomerDailyMax',
			placeholder: '请输入客户保有量',
			column: 12,
			labelWidth: '120px',
		},
	],
	[
		{
			label: '新线索',
			type: 'switch',
			attr: 'customerSwitch',
			column: 12,
			labelWidth: '120px',
		},
		{
			label: '状态',
			type: 'switch',
			attr: 'status',
			column: 12,
			labelWidth: '120px',
		},
	],
	[
		{
			label: '入职日期',
			type: 'date',
			attr: 'hiredate',
			placeholder: '入职日期',
			column: 12,
			labelWidth: '120px',
			valueFormat: 'YYYY-MM-DD',
		},
		{
			label: '员工状态',
			type: 'radio',
			attr: 'probation',
			placeholder: '',
			column: 12,
			labelWidth: '120px',
			option: [
				{ label: '正式', value: 1 },
				{ label: '试用', value: 0 },
			],
		},
	],
	[
		{
			label: '试用业绩',
			type: 'inputNumber',
			attr: 'probationPerformance',
			placeholder: '请输入试用期达标业绩',
			column: 12,
			labelWidth: '120px',
		},
		{
			label: '正式业绩',
			type: 'inputNumber',
			attr: 'regularPerformance',
			placeholder: '请输入正式达标业绩',
			column: 12,
			labelWidth: '120px',
		},
	]
]
export const passwordForms = [
	[
		{
			label: '新密码',
			type: 'input',
			attr: 'password',
			inputType: 'password',
			placeholder: '请输入新密码',
			column: 24,
			labelWidth: '120px',
		},
	],
	[
		{
			label: '确认密码',
			type: 'input',
			attr: 'password1',
            inputType: 'password',
			placeholder: '请再次确认密码',
			column: 24,
			labelWidth: '120px',
		},
	],
]
